function modal() {
  function openModal(modalSelector) {
    const modal = document.querySelector(modalSelector);
    modal.classList.add("show");
    modal.classList.remove("hide");
    document.body.style.overflow = "";
  }

  function closeModal(modalSelector) {
    const modal = document.querySelector(modalSelector);
    modal.classList.add("hide");
    modal.classList.remove("show");
    document.body.style.overflow = "";
  }

  window.closeModal = closeModal;
  window.openModal = openModal;

  function modal(triggerSelector, modalSelector) {
    const modalTrigger = document.querySelector(triggerSelector),
      modal = document.querySelector(modalSelector);
    console.log(modal);

    modalTrigger.addEventListener("click", () => {
      openModal(modalSelector);
    });

    modal.addEventListener("click", (e) => {
      if (e.target === modal || e.target.getAttribute("data-close") == "") {
        closeModal(modalSelector);
      }
    });

    document.addEventListener("keydown", (e) => {
      if (e.code === "Escape" && modal.classList.contains("show")) {
        closeModal(modalSelector);
      }
    });
  }

  modal("#modal-btn", ".modal");
  modal("#loginform__showReg", ".modal.regModal");
  modal("#resetform__showReset", ".modal.resetModal");
  closeModal(".modal");
}

module.exports = modal;
