function stuff() {
  function stuffRender(stuffs) {
    mainSpace.innerHTML = stuffs
      .map((stuff) => {
        return `<div class='stuff_box'>
        <div class='stuff_top'>
            <img src=${stuff.img}></img>
        </div>
        <div class='stuff_bottom'>
            <h5>${stuff.title}</h5>
            <br>
            <h5 style='text-align: justify'>${stuff.description}</h5>
            <br>
            <h5>${stuff.price}</h5>
        </div>
    </div>`;
      })
      .join("");
  }

  class Stuff {
    constructor(img, title, description, price) {
      this.img = img || "no img";
      this.title = title || "no title";
      this.description =
        description ||
        "Дистанционно <br><br>Диагностика, индивидуальная и групповая подготовка, вебинары <br><br> Методика: опорные конспекты и формулы (по системе Шаталова), эффективное чтение источников и карт, работа с авторскими карточками и пр. <br><br> Диагностика БЕСПЛАТНО";
      this.price = price;
    }
  }

  const mainSpace = document.querySelector(".stuff_container");
  console.log(mainSpace);

  const stuffHistory = [
    new Stuff(
      "./img/prepare.png",
      "Подготовка к ЕГЭ История/Обществознание",
      null,
      "1000 руб/час"
    ),
  ];
  stuffRender(stuffHistory);
}

module.exports = stuff;
