const axios = require("axios");
const { loginErrorText: errorText } = require("./errorProcess");

function auth() {
  document.getElementById("sign-in-form").onsubmit = (e) => {
    const login = document.getElementById("loginform__login").value;
    const password = document.getElementById("loginform__password").value;
    const remember =
      document.getElementById("loginform__remember").value === "on";

    e.preventDefault();

    axios
      .post("/api/auth/login", { login, password, remember })
      .then(({ data }) => {
        if (data.success === true) {
          localStorage.setItem("gamayuntoken", data.token);
          window.location.reload();
        } else errorText();
      })
      .catch((err) => {
        errorText(err.response?.data?.code);
      });
  };

  const token = localStorage.getItem("gamayuntoken");

  if (token)
    (async () => {
      await axios
        .post(
          "/api/auth/info",
          {},
          { headers: { Authorization: "Bearer " + token } }
        )
        .then(({ data }) => {
          if (data.success === true) {
            window.user = data.user;

            if (data.user.role === "admin")
              document
                .querySelector("#adminpanel__btn")
                .classList.remove("hide");

            document.getElementById("auth__logout").classList.remove("hide");
            document.getElementById("auth__icon").classList.add("hide");
          } else localStorage.removeItem("gamayuntoken");
        })
        .catch((err) => {
          console.error(err.response?.data);
          localStorage.removeItem("gamayuntoken");
        });
    })();

  document.getElementById("auth__logout").onclick = (e) => {
    e.preventDefault();
    localStorage.removeItem("gamayuntoken");
    window.location.reload();
  };
}

module.exports = auth;
