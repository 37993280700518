const axios = require("axios");
const { regErrorText: errorText } = require("./errorProcess");

function admin() {
  window.adminLoadUsers = async () => {
    const token = localStorage.getItem("gamayuntoken");

    return (
      await axios.get("/api/admin/getUsers", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
    ).data;
  };

  window.adminRemoveUser = async (id) => {
    const token = localStorage.getItem("gamayuntoken");

    await axios
      .post(
        "/api/admin/removeUser",
        { id },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(() => document.getElementById("adminCellUser" + id).remove())
      .catch((e) =>
        alert("Ошибка: " + regErrorText(e.response?.data?.code, true))
      );
  };

  window.adminChangeRole = async (id, role) => {
    const token = localStorage.getItem("gamayuntoken");

    await axios
      .post(
        "/api/admin/changeRole",
        { id, role },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .catch((e) =>
        alert("Ошибка: " + regErrorText(e.response?.data?.code, true))
      );
  };

  window.adminAddUser = async () => {
    const login = document.getElementById("adminNewUser__login").value;
    const email = document.getElementById("adminNewUser__email").value;
    const password = document.getElementById("adminNewUser__password").value;

    const token = localStorage.getItem("gamayuntoken");

    await axios
      .post(
        "/api/auth/register",
        { login, email, password },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(() => window.adminLoadUsersToTable())
      .catch((e) =>
        alert("Ошибка: " + regErrorText(e.response?.data?.code, true))
      );
  };
}

module.exports = admin;
