const axios = require("axios");
const { resetErrorText: errorText } = require("./errorProcess");

function reset() {
  window.resetErrorText = errorText;

  document.getElementById("reset-form").onsubmit = (e) => {
    const login = document.getElementById("resetform__login").value;
    const code = document.getElementById("resetform__code").value;

    e.preventDefault();

    if (
      document.getElementById("resetCodeInput").style.display === "block" &&
      !code
    )
      return;

    const errorBlock = document.getElementById("resetform__error");
    errorBlock.style.display = "block";
    errorBlock.innerText = "Отправляю данные...";

    axios
      .post("/api/auth/reset", { login, code })
      .then(({ data }) => {
        if (data.success === true) {
          const codeInput = document.getElementById("resetCodeInput");

          if (codeInput.style.display === "none" && data.stage === "sent") {
            codeInput.style.display = "block";
            errorBlock.innerText = "На Вашу почту отправлен код. Введите его.";
          } else if (data.stage === "accepted") {
            codeInput.style.display = "none";
            errorBlock.innerText = "Новый пароль был отправлен на Вашу почту.";
          }
        } else errorText();
      })
      .catch((err) => {
        errorText(err.response?.data?.code);
      });
  };
}

module.exports = reset;
