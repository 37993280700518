module.exports.regErrorText = (code, d = false) => {
  const errorBlock = !d
    ? document.getElementById("regform__error")
    : { innerText: "" };
  if (!d) errorBlock.style.display = "block";

  if (!code) {
    errorBlock.innerText = "Неизвестная ошибка";
  } else if (code === 100 || code === 101)
    errorBlock.innerText = "Сессия устарела, перезагрузите страницу";
  else if (code === 103) errorBlock.innerText = "Не все поля заполнены!";
  else if (code === 104) errorBlock.innerText = "Неверный E-Mail!";
  else if (code === 105) errorBlock.innerText = "Слишком длинный логин!";
  else if (code === 106) errorBlock.innerText = "Этот E-Mail уже занят!";
  else if (code === 107) errorBlock.innerText = "Этот логин уже занят!";

  return errorBlock.innerText;
};

module.exports.loginErrorText = (code, d = false) => {
  const errorBlock = !d
    ? document.getElementById("loginform__error")
    : { innerText };
  if (!d) errorBlock.style.display = "block";

  if (!code) {
    errorBlock.innerText = "Неизвестная ошибка";
  } else if (code === 100 || code === 101)
    errorBlock.innerText = "Сессия устарела, перезагрузите страницу";
  else if (code === 102)
    errorBlock.innerText = "Неверный логин/почта или пароль";
  else if (code === 103)
    errorBlock.innerText = "Вы не указали логин/почту или пароль";

  return errorBlock.innerText;
};

module.exports.resetErrorText = (code, d = false) => {
  const errorBlock = !d
    ? document.getElementById("resetform__error")
    : { innerText };
  if (!d) errorBlock.style.display = "block";

  if (!code) errorBlock.innerText = "Неизвестная ошибка";
  else if (code === 101) errorBlock.innerText = "Вы не указали логин/пароль";
  else if (code === 102) errorBlock.innerText = "Неверный логин/почта";
  else if (code === 103) errorBlock.innerText = "Неверный код";

  return errorBlock.innerText;
};
