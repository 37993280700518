window.addEventListener("DOMContentLoaded", () => {
  // import slider from './modules/slider';
  const modal = require("./modules/modal"),
    header = require("./modules/header"),
    stuff = require("./modules/stuff"),
    test = require("./modules/test"),
    slider = require("./modules/slider"),
    themes = require("./modules/themes"),
    auth = require("./modules/auth"),
    register = require("./modules/register"),
    reset = require("./modules/reset"),
    admin = require("./modules/admin");

  modal();
  header();
  test();
  stuff();
  slider();
  themes();
  auth();
  register();
  reset();
  admin();
});
