const axios = require("axios");
const { regErrorText: errorText } = require("./errorProcess");

function register() {
  window.regErrorText = errorText;

  document.getElementById("sign-up-form").onsubmit = (e) => {
    const login = document.getElementById("regform__login").value;
    const email = document.getElementById("regform__email").value;
    const password = document.getElementById("regform__password").value;

    e.preventDefault();

    axios
      .post("/api/auth/register", { login, email, password })
      .then(({ data }) => {
        if (data.success === true) {
          window.closeModal(".regModal");
          const errorBlock = document.getElementById("loginform__error");
          errorBlock.style.display = "block";
          errorBlock.innerText =
            "Регистрация завершена. Теперь Вы можете войти в аккаунт.";
        } else errorText();
      })
      .catch((err) => {
        errorText(err.response?.data?.code);
      });
  };
}

module.exports = register;
