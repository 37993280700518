function header() {
  function smtTo(element, adding, removing) {
    element.classList.add(adding);
    element.classList.remove(removing);
  }

  function toHide(element) {
    smtTo(element, "hide", "show");
  }

  function toShow(element) {
    smtTo(element, "show", "hide");
  }

  function burgerFunc(burger, block) {
    burger.classList.remove("active");
    block.classList.remove("active");
    document.body.classList.remove("lock");
  }

  async function adminLoadUsers() {
    const body = document.querySelector("#adminUsersTable tbody");
    body.innerHTML = "";

    (await window.adminLoadUsers()).map((user) => {
      body.insertAdjacentHTML(
        "beforeend",
        '<tr id="adminCellUser' +
          user.id +
          '">' +
          '<td class="thtable">' +
          user.id +
          "</td>" +
          '<td class="thtable">' +
          user.username +
          "</td>" +
          '<td class="thtable">' +
          user.email +
          "</td>" +
          '<td class="thtable">' +
          '<select onchange="window.adminChangeRole(' +
          user.id +
          ', this.value)">' +
          '<option value="user1" ' +
          (user.role === "user1" ? "selected" : "") +
          ">Пользователь 1</option>" +
          '<option value="user2" ' +
          (user.role === "user2" ? "selected" : "") +
          ">Пользователь 2</option>" +
          '<option value="admin" ' +
          (user.role === "admin" ? "selected" : "") +
          ">Админ</option>" +
          "</select>" +
          "</td>" +
          '<td class="thtable"><a href="#" onclick="window.adminRemoveUser(' +
          user.id +
          ')">Удалить</a></td>' +
          "</tr>"
      );
    });
  }

  window.adminLoadUsersToTable = adminLoadUsers;

  const goods = document.querySelector("#goods"),
    pract = document.querySelector("#pract"),
    offer = document.querySelector(".offer"),
    reviews = document.querySelector("#reviews"),
    stuff = document.querySelector(".stuff"),
    contact = document.querySelector("#contact"),
    contacts = document.querySelector(".contacts"),
    adminpanelbtn = document.querySelector("#adminpanel__btn"),
    adminpanel = document.querySelector(".adminpanel"),
    practicum = document.querySelector(".practicum"),
    burger = document.querySelector(".header__burger"),
    leftBLock = document.querySelector(".header__left-block");

  burger.addEventListener("click", () => {
    burger.classList.toggle("active");
    leftBLock.classList.toggle("active");
    document.body.classList.toggle("lock");
  });

  goods.addEventListener("click", () => {
    toHide(offer);
    toHide(practicum);
    toShow(stuff);
    toHide(contacts);
    toHide(adminpanel);
    burgerFunc(burger, leftBLock);
  });

  pract.addEventListener("click", () => {
    toHide(offer);
    toHide(stuff);
    toHide(contacts);
    toShow(practicum);
    toHide(adminpanel);
    burgerFunc(burger, leftBLock);
  });

  reviews.addEventListener("click", () => {
    toShow(offer);
    toHide(stuff);
    toHide(contacts);
    toHide(practicum);
    toHide(adminpanel);
    burgerFunc(burger, leftBLock);
  });

  contact.addEventListener("click", () => {
    toHide(offer);
    toHide(stuff);
    toHide(practicum);
    toShow(contacts);
    toHide(adminpanel);
    burgerFunc(burger, leftBLock);
  });

  adminpanelbtn.addEventListener("click", async () => {
    toHide(offer);
    toHide(stuff);
    toHide(practicum);
    toHide(contacts);
    toShow(adminpanel);
    burgerFunc(burger, leftBLock);

    adminLoadUsers();
  });
}

module.exports = header;
