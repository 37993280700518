function theme() {
    let lightTheme = "css/light.css",
        darkTheme = "css/dark.css",
        btn = document.getElementById("theme-button"),
        link = document.getElementById("theme-link"),
        currTheme = link.getAttribute("href");

    btn.addEventListener("click", () => { ChangeTheme(); });

    function ChangeTheme() {
        let theme = "";

        if (currTheme == lightTheme) {
            currTheme = darkTheme;
            theme = "dark";
        } else {
            currTheme = lightTheme;
            theme = "light";
        }

        link.setAttribute("href", currTheme);
        localStorage.theme = theme || "light";
    }

    if (!localStorage.theme) localStorage.theme = "light";

    if (localStorage.theme === "dark") {
        currTheme = darkTheme;
        theme = "dark";
        link.setAttribute("href", currTheme);
        localStorage.theme = theme;
    } else {
        currTheme = lightTheme;
        theme = "light";
        link.setAttribute("href", currTheme);
        localStorage.theme = theme;
    }
}
module.exports = theme;